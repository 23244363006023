<template>
    <div class="text-center">
      <v-card-subtitle class="yellowPlexi form-subtitle">Select Provider</v-card-subtitle>
        <v-list
          width="100%"
        >
          <v-row>
            <v-col cols=12>
              <v-card-text>
                <v-text-field
                  v-model="searchTextProviders"
                  single-line
                  density="compact"
                  label="Filter Providers"
                  append-inner-icon="mdi-filter"
                  class="filter-box"
                ></v-text-field>
              </v-card-text>
            </v-col>
          </v-row>
          <v-list-item class="items"
            v-for="provider in filteredProviders"
            :key="provider.uuid"
          >
            <v-row @click="clickIcon(provider.brand,provider.authentication)">
              <v-col cols=1>
                <v-icon class="yellowPlexi icon-provider"><i class="fas fa-chevron-right"></i></v-icon>
              </v-col>
              <v-col cols =6 class="col-provider">
                <div class ="subdevice yellowPlexi pointer" >
                {{ provider.brand }}
                </div>
              </v-col>
              <v-col cols =5>
                <v-img
                  class="mr-3 icon-brand"
                  :src=provider.image
                  height="50"
                  width="50"

                />
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>

      <v-btn type="button" variant="tonal" @click="cancel"  block class="mt-2">Cancel</v-btn>
    </div>

</template>

<script>

export default {
  name: 'AddproviderProvider',
  props: {
    providers: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    searchTextProviders: ''

  }),
  computed: {

    filteredProviders () {
      const filterText = this.searchTextProviders.toLowerCase().trim()
      if (!filterText) {
        return this.providers
      } else {
        return this.providers.filter((provider) =>
          provider.brand.substring(0, 6).toLowerCase().includes(filterText)
        )
      }
    }
  },
  methods: {
    async submit (event) {
      // const results = await event
      // alert(JSON.stringify(results, null, 2))
      this.dialog = true
      setTimeout(() => (this.sendDialog = true), 4000)
    },
    cancel () {
      this.$store.dispatch('setElement', { path: 'plantRegistration.showForm', value: false })
    },
    clickIcon (brand, authentication) {
      this.$store.dispatch('setElement', { path: 'plantRegistration.addPlantProvider', value: false })
      this.$store.dispatch('setElement', { path: 'plantRegistration.addPlantCredentials', value: true })
      this.$store.dispatch('setElement', { path: 'plantRegistration.provider', value: brand })
      this.$store.dispatch('setElement', { path: 'plantRegistration.authentication', value: authentication })
    }
  }
}
</script>

<style scoped>
.v-info-title{
  font-size: 17px;
  margin-top:-15px;
}
.v-info-text{
  margin-top:2px!important;
}

.v-info-combo{
  margin-top:-10px!important;
  margin-bottom: -20px;
}

.card-info{
  margin-top:15px;
}
.info{
  margin-top: 10px;
}
.row-info{
  margin-bottom: -16px;
}
.BMSForm{
  padding: 20px;

}
.icon-dialog{
  padding: 40px;
margin-top: 0px;
}
.input-field{
  height: 70px;
}
.form-subtitle{
  font-size: 20px;
}
.icon-provider{
  margin-top: 5px;
  margin-left: 20px;
}
.icon-brand{
  cursor:pointer;
}
</style>
